<template>
  <div id="simple-calendar-app">
    <item-page
      v-on:didAddItem="didAddItem"
      :isSidebarActive="ItemPage"
      :isNewItem="isNewItem"
      :editItemData="editItemData"
      :selectedDate="selectedDate"
      @closeSidebar="ItemPage = false"
    />

    <div class="vx-card no-scroll-content">
      <calendar-view
        ref="calendar"
        :displayPeriodUom="calendarView"
        :show-date="showDate"
        :events="items"
        enableDragDrop
        :eventTop="windowWidth <= 400 ? '2rem' : '3rem'"
        eventBorderHeight="0px"
        eventContentHeight="1.65rem"
        class="theme-default"
        locale="pt-BR"
        @click-date="openAddNewEvent"
        @click-event="openEditEvent"
        @drop-on-date="eventDragged"
      >
        <div slot="header" class="mb-4">
          <div class="vx-row no-gutter">
            <!-- Month Name -->
            <div class="vx-col w-1/3 items-center sm:flex hidden">
              <!-- Add new event button -->
              <!-- <vs-button icon-pack="feather" icon="icon-plus" @click="promptAddNewEvent(new Date())">Add</vs-button> -->
            </div>

            <!-- Current Month -->
            <div
              class="vx-col sm:w-1/3 w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last"
            >
              <div class="flex items-center">
                <feather-icon
                  :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                  @click="updateMonth(-1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full"
                />

                <span class="mx-3 text-xl font-medium whitespace-no-wrap">{{
                  currentMonth
                }}</span>

                <feather-icon
                  :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                  @click="updateMonth(1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full"
                />
              </div>
            </div>

            <div class="vx-col sm:w-1/3 w-full flex justify-center">
              <template v-for="(view, index) in calendarViewTypes">
                <vs-button
                  v-if="calendarView === view.val"
                  :key="String(view.val) + 'filled'"
                  type="filled"
                  class="p-3 md:px-8 md:py-3"
                  :class="{
                    'border-l-0 rounded-l-none': index,
                    'rounded-r-none': calendarViewTypes.length !== index + 1
                  }"
                  @click="calendarView = view.val"
                  >{{ view.label }}</vs-button
                >
                <vs-button
                  v-else
                  :key="String(view.val) + 'border'"
                  type="border"
                  class="p-3 md:px-8 md:py-3"
                  :class="{
                    'border-l-0 rounded-l-none': index,
                    'rounded-r-none': calendarViewTypes.length !== index + 1
                  }"
                  @click="calendarView = view.val"
                  >{{ view.label }}</vs-button
                >
              </template>
            </div>
          </div>

          <div class="vx-row sm:flex hidden mt-4">
            <div class="vx-col w-full flex">
              <!-- Labels -->
              <div class="flex flex-wrap sm:justify-start justify-center">
                <div
                  v-for="(label, index) in calendarLabels"
                  :key="index"
                  class="flex items-center mr-4 mb-2"
                >
                  <div
                    class="h-3 w-3 inline-block rounded-full mr-2"
                    :class="'bg-' + label.color"
                  ></div>
                  <span>{{ label.text }}</span>
                </div>
                <div class="flex items-center mr-4 mb-2">
                  <div
                    class="h-3 w-3 inline-block rounded-full mr-2 bg-primary"
                  ></div>
                  <span>Hoje</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </calendar-view>
    </div>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import ggcsApi from "@/services/ggcs";

require("vue-simple-calendar/static/css/default.css");
import moment from "moment";

import ItemPage from "./Page.vue";

export default {
  components: {
    CalendarView,
    CalendarViewHeader,
    ItemPage
  },
  data() {
    return {
      showDate: new Date(),
      disabledFrom: false,
      id: 0,
      title: "",
      startDate: "",
      endDate: "",
      labelLocal: "none",

      ItemPage: false,
      isNewItem: true,
      editItemData: {},
      selectedDate: null,

      items: [],

      url: "",
      calendarView: "month",

      activePromptAddEvent: false,
      activePromptEditEvent: false,

      calendarViewTypes: [
        {
          label: "Mês",
          val: "month"
        }
        // {
        //   label: "Semana",
        //   val: "week"
        // },
        // {
        //   label: "Ano",
        //   val: "year"
        // },
      ]
    };
  },
  computed: {
    currentMonth() {
      return moment(this.showDate).format("MMMM/YYYY");
    },
    simpleCalendarEvents() {
      return [];
    },
    validForm() {
      return (
        this.title != "" &&
        this.startDate != "" &&
        this.endDate != "" &&
        Date.parse(this.endDate) - Date.parse(this.startDate) >= 0 &&
        !this.errors.has("event-url")
      );
    },
    disabledDatesTo() {
      return { to: new Date(this.startDate) };
    },
    disabledDatesFrom() {
      return { from: new Date(this.endDate) };
    },
    calendarLabels() {
      return [];
    },
    labelColor() {
      return label => {
        if (label == "business") return "success";
        else if (label == "work") return "warning";
        if (label == "personal") return "danger";
        if (label == "none") return "primary";
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  },
  methods: {
    updateMonth(val) {
      const date = this.$refs.calendar.getIncrementedPeriod(val);
      //this.showDate = moment(date).format('MMMM/YYYY');
      this.showDate = date;
    },
    clearFields() {
      this.title = this.endDate = this.url = "";
      this.id = 0;
      this.labelLocal = "none";
    },
    promptAddNewEvent(date) {
      this.addNewEventDialog(date);
    },
    addNewEventDialog(date) {
      this.clearFields();
      this.startDate = date;
      this.endDate = date;
      this.activePromptAddEvent = true;
    },
    openAddNewEvent(date) {
      const buffet = this.items.find(item => {
        return moment(item.date).isSame(date, "day");
      });
      this.selectedDate = date;
      if (buffet) {
        this.isNewItem = false;
        this.editItemData = buffet;
        this.ItemPage = true;
      } else {
        this.ItemPage = true;
        this.isNewItem = true;
      }
    },
    openEditEvent(event) {
      this.selectedDate = event.startDate;
      this.isNewItem = false;
      this.editItemData = this.items.find(item => {
        return item.agenda_id == event.id;
      });
      this.ItemPage = true;
    },
    editEvent() {
      const obj = {
        id: this.id,
        title: this.title,
        startDate: this.startDate,
        endDate: this.endDate,
        label: this.labelLocal,
        url: this.url
      };
      obj.classes = "event-" + this.labelColor(this.labelLocal);
      this.$store.dispatch("calendar/editEvent", obj);
    },
    removeEvent() {
      this.$store.dispatch("calendar/removeEvent", this.id);
    },
    eventDragged(event, date) {
      this.$store.dispatch("calendar/eventDragged", {
        event: event,
        date: date
      });
    },
    fetch() {
      this.renderLoading();
      const self = this;
      ggcsApi
        .get("buffets/agenda")
        .then(res => {
          self.$vs.loading.close();
          const { data } = res;
          if (data.success) {
            if (data.result) {
              self.items = data.result.map(item => {
                item.title = "Cardápio";
                item.startDate = item.date;
                item.endDate = item.date;
                item.classes = "event-success";
                item.id = item.agenda_id;
                return item;
              });
            }
          }
          self.isLoading = false;
        })
        .catch(() => {
          self.$vs.loading.close();

        });
    },
    renderLoading() {
      this.$vs.loading({
        scale: 0.45
      });
    },
    didAddItem() {
      // console.error("Error result: ", document); // @TODO
      // const index = this.items.findIndex(item => {
      //   return moment(item.date).isSame(document.date, "day");
      // });
      // console.log(index);
      // if (index) {
      //   this.items[index] = document;
      // } else {
      //   this.items.push(document);
      // }
      // console.log(this.items);
      this.fetch();
    }
  },
  created() {
    moment.locale("pt-br");
    this.fetch();
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/simple-calendar.scss";
</style>
