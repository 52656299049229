<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ isNewItemLocal ? "Novo" : "Alterar" }} item {{ formatDate }}</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new p-5"
      :settings="settings"
    >
      <form>
        <p>Prato 1*:</p>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="buffet.plate1"
          :options="platesByType(1)"
          class="mb-2"
        />

        <p>Prato 2*:</p>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="buffet.plate2"
          :options="platesByType(1)"
          class="mb-2"
        />

        <p>Prato 3:</p>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="buffet.plate3"
          :options="platesByType(1)"
          class="mb-2"
        />

        <p>Guarnição*:</p>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="buffet.garrison"
          :options="platesByType(3)"
          class="mb-2"
        />

        <p>Salada:</p>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="buffet.salad"
          :options="platesByType(6)"
          class="mb-2"
        />

        <p>Sobremesa*:</p>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="buffet.dessert"
          :options="platesByType(4)"
          class="mb-2"
        />

        <p>Sopa:</p>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="buffet.soup"
          :options="platesByType(5)"
          class="mb-2"
        />

        <p>Light 1*:</p>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="buffet.light1"
          :options="platesByType(2)"
          class="mb-2"
        />

        <p>Light 2:</p>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="buffet.light2"
          :options="platesByType(2)"
          class="mb-2"
        />

        <p>Prato Especial:</p>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="buffet.special"
          :options="platesByType(7)"
          class="mb-2"
        />
      </form>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitAction">Salvar</vs-button>
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >Cancelar</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import moment from "moment";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import ggcsApi from "@/services/ggcs";

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    isNewItem: {
      type: Boolean,
      required: true
    },
    editItemData: {
      type: Object,
      required: false
    },
    selectedDate: {
      type: Date,
      required: false
    }
  },
  data() {
    return {
      plates: [],
      buffet: {
        plate1: null,
        plate2: null,
        plate3: null,
        light1: null,
        light2: null,
        garrison: null,
        dessert: null,
        salad: null,
        soup: null,
        date: null,
        special: null
      },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  watch: {
    editItemData() {
      const data = this.editItemData;
      this.buffet.plate1 = this.plates.find(item => item.label == data.plate_1);
      this.buffet.plate2 = this.plates.find(item => item.label == data.plate_2);
      this.buffet.plate3 = this.plates.find(item => item.label == data.plate_3);
      this.buffet.garrison = this.plates.find(
        item => item.label == data.garrison
      );
      this.buffet.dessert = this.plates.find(
        item => item.label == data.dessert
      );
      this.buffet.salad = this.plates.find(item => item.label == data.salad);
      this.buffet.light1 = this.plates.find(item => item.label == data.light_1);
      this.buffet.light2 = this.plates.find(item => item.label == data.light_2);
      this.buffet.soup = this.plates.find(item => item.label == data.soup);
      this.buffet.special = this.plates.find(item => item.label == data.special);
    },
    isNewItem(newVal) {
      if (newVal) {
        this.clearData();
      }
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    },
    isNewItemLocal: {
      get() {
        return this.isNewItem;
      }
    },
    formatDate: {
      get() {
        return moment(this.selectedDate).format("DD/MM");
      }
    }
  },
  methods: {
    platesByType(type) {
      return this.plates.filter(plate => {
        return plate.type === type;
      });
    },
    submitAction() {
      this.renderLoading();
      if (this.isNewItemLocal) {
        this.createSubmit();
      } else {
        this.updateSubmit();
      }
    },
    mapData() {
      const { buffet } = this;
      const date = moment(this.selectedDate).format("YYYY-MM-DD");
      return {
        date,
        plate1: buffet.plate1 ? buffet.plate1.id : null,
        plate2: buffet.plate2 ? buffet.plate2.id : null,
        plate3: buffet.plate3 ? buffet.plate3.id : null,
        light1: buffet.light1 ? buffet.light1.id : null,
        light2: buffet.light2 ? buffet.light2.id : null,
        garrison: buffet.garrison ? buffet.garrison.id : null,
        salad: buffet.salad ? buffet.salad.id : null,
        dessert: buffet.dessert ? buffet.dessert.id : null,
        soup: buffet.soup ? buffet.soup.id : null,
        special: buffet.special ? buffet.special.id : null
      };
    },
    createSubmit() {
      const payload = this.mapData();
      const self = this;
      self.$vs.loading.close();
      ggcsApi
        .post("buffets/agenda", payload)
        .then(res => {
          self.$vs.loading.close();
          const { data } = res;
          if (data.success) {
            self.notifySuccess();
            self.isSidebarActiveLocal = false;
            self.clearData();

            payload.agenda_id = data.result;
            self.$emit("didAddItem", payload);
          }
        })
        .catch(() => {
          self.$vs.loading.close();
        });
    },
    updateSubmit() {
      const payload = this.mapData();
      const id = this.editItemData.agenda_id;
      const self = this;
      ggcsApi
        .put(`buffets/agenda/${id}`, payload)
        .then(res => {
          self.$vs.loading.close();
          const { data } = res;
          if (data.success) {
            self.notifySuccess();

            payload.agenda_id = id;
            self.$emit("didAddItem", payload);
          }
          self.clearData();
          self.isSidebarActiveLocal = false;
        })
        .catch(() => {
          self.$vs.loading.close();
          //console.error("Error result: ", error);
        });
    },
    fetchPlates() {
      const self = this;
      ggcsApi
        .get(`buffets/plates/`)
        .then(res => {
          const { data } = res;
          if (data.success) {
            self.plates = data.result.map(plate => {
              return {
                id: plate.plate_id,
                label: plate.name,
                type: plate.type
              };
            });
          }
        })
        .catch(() => {
          self.$vs.loading.close();
        });
    },
    clearData() {
      this.buffet = {
        plate1: null,
        plate2: null,
        plate3: null,
        light1: null,
        light2: null,
        garrison: null,
        dessert: null,
        salad: null,
        soup: null,
        date: null,
        special: null,
      };
    },
    renderLoading() {
      this.$vs.loading({
        // container: "#items-list-view",
        scale: 0.45
      });
    },
    notifySuccess() {
      const text = this.isNewItemLocal ? "adicionado" : "alterado";
      this.$vs.notify({
        title: "Sucesso",
        text: `Item ${text} com sucesso`,
        color: "success",
        iconPack: "feather",
        icon: "icon-check",
        position: "top-right"
      });
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  created() {
    this.fetchPlates();
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  /deep/ .vs-sidebar--background {
    z-index: 52010;
  }

  /deep/ .vs-sidebar {
    z-index: 52010;
    width: 500px;
    max-width: 90vw;
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
